import Vue from 'vue'
import './style/global.css'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import plugin from './plugins'
import './assets/icons' // icon
import store from './store/index'
import VueClipboard from 'vue-clipboard2'
import 'animate.css';


Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(plugin)
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
