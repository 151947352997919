<template>
  <div class="index">
    <!--用户操作区域 start-->
    <div class="operate">
      <ul>
        <li>
          <el-button type="primary" size="mini" @click="uploadDialogVisible = true">上传文件</el-button>
        </li>
        <li>
          <el-button type="info" size="mini" @click="mkdirDialogVisible = true">新建文件夹</el-button>
        </li>
        <li>
          <el-button type="danger" size="mini" @click="handleBatchDelete()">批量删除文件</el-button>
        </li>
      </ul>
    </div>
    <!--用户操作区域 end-->

    <!--面包屑 start-->
    <div class="breadcrumb" style="margin-top: 10px;height: 20px;">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="animate__animated animate__slideInLeft"></el-breadcrumb-item>
        <el-breadcrumb-item v-if="breadcrumbsCopy.length>0" class="animate__animated animate__slideInLeft">
                <span style="cursor: pointer" @click="back()">
                  <svg-icon icon-class="back"></svg-icon>
                  上一级
                </span>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-for="(item,index) in breadcrumbsCopy" :key="index"
                            class="animate__animated animate__slideInLeft">
          <span>{{ item.name }}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!--面包屑 end-->

    <!--文件上传dialog start-->
    <el-dialog
      :visible.sync="uploadDialogVisible"
      width="30%"
      center destroy-on-close>
      <upload-view @child-to-parent="handleMessageFromChild"></upload-view>
    </el-dialog>
    <!--文件上传dialog end-->

    <!--新增文件夹dialog start-->
    <el-dialog
      title="新增文件夹"
      :visible.sync="mkdirDialogVisible"
      width="30%"
      center destroy-on-close>
      <el-input v-model="folderName" placeholder="请输入内容" @keyup.enter.native="mkdir()"></el-input>
      <span slot="footer" class="dialog-footer">
              <el-button @click="mkdirDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="mkdir()">确 定</el-button>
            </span>
    </el-dialog>
    <!--新增文件夹dialog end-->

    <!--重命名文件、文件夹dialog start-->
    <el-dialog
      title="重命名"
      :visible.sync="renameDialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center destroy-on-close :before-close="renameFileClose">
      <el-input v-model="renameFile" @keyup.enter.native="handleRename()"></el-input>
      <el-input :value="renameFileType" disabled v-if="showRenameFileTypeFlag"></el-input>
      <span slot="footer" class="dialog-footer">
              <el-button @click="renameDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="handleRename()">确 定</el-button>
            </span>
    </el-dialog>
    <!--重命名文件、文件夹dialog end-->

    <!--文件下载popover start-->
    <el-popover
      ref="popoverDownload"
      placement="bottom"
      width="400"
      trigger="manual">
      <div class="show_file">
        <!--文件 名称 进度-->
        <h1>文件名：{{ downloadFileName }}</h1>
        <el-progress :percentage="downloadPercent"></el-progress>
      </div>
    </el-popover>
    <!--文件下载popover end-->

    <!--分享文件dialog start-->
    <el-dialog
      :close-on-click-modal="false"
      :title="`分享文件:${shareFileName}`"
      :modal="false"
      :visible.sync="shareDialogVisible"
      width="30%"
      center destroy-on-close>
      <div class="share">
        <el-form :model="shareForm" :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="有效期：" prop="expired">
            <el-radio-group v-model="shareForm.expired">
              <el-radio label="1">1天</el-radio>
              <el-radio label="7">7天</el-radio>
              <el-radio label="30">30天</el-radio>
              <el-radio label="0">永久有效</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="提取码：" prop="extractCode">
            <div class="extract">
              <el-radio-group v-model="shareForm.extractMode">
                <el-radio label="system" style="display: block">系统随机生成随机码</el-radio>
                <el-radio label="manual" style="display: block;margin-top: 20px">
                  <el-input
                    :disabled="shareForm.extractMode == 'system'"
                    v-model="shareForm.extractCode"
                    placeholder="请输入随机码"
                    style="width: 150px;"
                    clearable>
                  </el-input>
                </el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="createLink('ruleForm')" round size="mini">创建链接</el-button>
          </el-form-item>
        </el-form>
        <div>
          <el-input style="width: 400px;" disabled ref="shareLink" v-model="shareLink">
            <template slot="prepend">生成链接</template>
          </el-input>
          <el-button type="primary" @click="copyLink()" size="small"
                     style="display: inline-block;margin-left: 10px">
            复制链接
          </el-button>
        </div>
      </div>

    </el-dialog>
    <!--分享文件dialog end-->

    <!--预览文件dialog start-->
    <el-dialog
      :key="previewContent.id"
      :title="previewContent.name"
      :visible.sync="previewVisible"
      width="100"
      destroy-on-close
      :close-on-click-modal="false">
      <preview></preview>
    </el-dialog>
    <!--预览文件dialog end-->

    <!--文件列表信息开始-->
    <div class="file_list">
      <div class="file_category_menu">
        <el-menu
          mode="horizontal"
          default-active="0"
          class="el-menu-vertical-demo"
          @select="handleSelect">
          <el-menu-item index="0">
            <i>
              <svg-icon icon-class="all"></svg-icon>
            </i>
            <span slot="title">全部</span>
          </el-menu-item>
          <el-menu-item index="1">
            <i>
              <svg-icon icon-class="musicAlbum"></svg-icon>
            </i>
            <span slot="title">音频</span>
          </el-menu-item>
          <el-menu-item index="2">
            <i>
              <svg-icon icon-class="video"></svg-icon>
            </i>
            <span slot="title">视频</span>
          </el-menu-item>
          <el-menu-item index="3">
            <i>
              <svg-icon icon-class="image"></svg-icon>
            </i>
            <span slot="title">图片</span>
          </el-menu-item>
          <el-menu-item index="4">
            <i>
              <svg-icon icon-class="document"></svg-icon>
            </i>
            <span slot="title">文档</span>
          </el-menu-item>
          <el-menu-item index="5">
            <i>
              <svg-icon icon-class="another"></svg-icon>
            </i>
            <span slot="title">其他</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="file_category_file_list">
        <el-table
          :data="fileList"
          style="width: 100%"
          max-height="700"
          @selection-change="handleSelectionChange">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column prop="fileCover" width="43">
            <template slot-scope="scope">
              <el-image :src="scope.row.fileCover" width="40" height="40"/>
            </template>
          </el-table-column>
          <el-table-column
            label="文件名">
            <template slot-scope="scope">
              <span class="columnFileName" @click="handleEnterFolder(scope.row)">{{ scope.row.fileName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="updateTime"
            label="修改日期">
          </el-table-column>
          <el-table-column
            prop="fileSizeConvertor"
            label="大小"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleRenameDialogVisible(scope.$index, scope.row)">重命名
              </el-button>
              <el-button
                v-if="scope.row.folderType === 0"
                size="mini"
                @click="handleDownload(scope.$index, scope.row)">下载
              </el-button>
              <el-button
                v-if="scope.row.folderType === 0"
                size="mini"
                @click="handleShare(scope.$index, scope.row)">分享
              </el-button>
              <el-button
                size="mini"
                @click="handleDelete(scope.$index, scope.row)">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>
    <!--文件列表信息结束-->
  </div>
</template>
<script>
import uploadView from "@/components/Upload/UploadView.vue";
import preview from "@/components/Preview/Preview.vue";
import {getFileList, mkdir, deleteFile, renameFile, downloadFile, getFileChunksInfo, createLink} from '@/api/file.js'
import {removeToken} from '@/utils/auth'
import {logout} from '@/api/login'

export default {
  data() {
    return {
      previewContent: {},
      breadcrumbs: [], // 面包屑导航
      breadcrumbsCopy: [],
      downloadPercent: 0, // 下载百分比
      downloadFileSize: 0, // 下载文件的大小
      uploadDialogVisible: false, // 上传文件组件Dialog
      mkdirDialogVisible: false,  // 新增文件夹组件Dialog
      renameDialogVisible: false, // 重命名文件组件Dialog
      shareDialogVisible: false,  // 分享文件组件Dialog
      previewVisible: false, // 文件预览组件Dialog
      shareFileName: '',  // 分享文件名称
      shareLink: '',  // 分享文件链接
      folderName: "", // 文件夹名称
      downloadFileName: "", // 下载文件的名称
      fileCategory: 0,  // 文件分类
      filePid: '',
      fileList: [], // 文件列表
      id: "", // 文件id
      ids: [],
      renameFile: "", // 重命名文件
      renameFileType: "", // 重命名文件类型
      showRenameFileTypeFlag: false,  // 重命名显示文件类型
      downloadChunk: [], // 下载文件分片列表
      shareForm: {
        expired: '1',
        extractMode: 'system',
        extractCode: '',
      },
      rules: {
        expired: [
          {required: true, message: '请选择有效时间', trigger: 'blur'},
        ],
        extractMode: [
          {required: true, message: '请选择提取方式', trigger: 'blur'},
        ],
        extractCode: [
          {required: false, message: '请填写提取码', trigger: 'blur'},
        ]
      }
    };
  },
  components: {uploadView, preview},
  created() {
  },
  mounted() {
    this.setBreadcrumbs();
    this.getFileList();
  },
  watch: {
    breadcrumbs: {
      handler(newVal, oldVal) {
        console.log('Items:', oldVal);
        console.log('Items array has changed:', newVal.length);
        let item = [];
        for (let i = 0; i < newVal.length; i++) {
          item.push(newVal[i]);
        }
        sessionStorage.setItem("breadcrumbs", JSON.stringify(item))
        this.setBreadcrumbs();
      },
      deep: true,
    }
  },
  methods: {
    // 设置面包屑
    setBreadcrumbs() {
      let search = JSON.parse(sessionStorage.getItem("breadcrumbs"));
      if (search == null) {
        this.filePid = '';
        return;
      }
      console.log("setBreadcrumbs.search:", search)
      let var1 = [];
      for (let i = 0; i < search.length; i++) {
        console.log("search[i]", search[i]);
        var1.push(search[i]);
      }
      console.log("setBreadcrumbs", var1);
      this.breadcrumbsCopy = var1;
      if (this.breadcrumbsCopy.length > 0) {
        this.filePid = this.breadcrumbsCopy[this.breadcrumbsCopy.length - 1].id;
      } else {
        this.filePid = '';
      }
    },
    // 返回到上一级目录
    back() {
      console.log("back");
      this.breadcrumbs = this.breadcrumbsCopy;
      this.breadcrumbs.pop();
      console.log("this.breadcrumbsCopy:", this.breadcrumbs);
      console.log("this.breadcrumbsCopy.pop:", this.breadcrumbsCopy);
      if (this.breadcrumbs.length > 0) {
        this.filePid = this.breadcrumbs[this.breadcrumbs.length - 1].id;
      } else {
        this.filePid = '';
      }
      this.getFileList();
    },
    // 根据文件类型查询文件列表
    getFileList() {
      getFileList(this.fileCategory, this.filePid).then(res => {
        this.fileList = res.data.data;
      }).catch(() => {
      });
    },
    // 创建文件夹
    mkdir() {
      console.log("创建文件夹：", this.breadcrumbs)
      let path = '';
      let parentName = '';
      if (this.breadcrumbs.length > 0) {
        for (let i = 0; i < this.breadcrumbs.length; i++) {
          path += this.breadcrumbs[i].id + '/';
          parentName += this.breadcrumbs[i].name + '/';
        }
      }
      mkdir({folderName: this.folderName, filePath: path, parentName: parentName}).then(res => {
        if (res.data.code == 200) {
          this.$modal.msgSuccess("创建成功");
        }
        this.folderName = "";
        this.mkdirDialogVisible = false;
        this.getFileList();
      }).catch(() => {
      });

    },
    // 接收子组件消息
    handleMessageFromChild(message) {
      console.log("handleMessageFromChild" + message)
      this.$modal.msgSuccess("文件上传成功");
      this.uploadDialogVisible = false;
      this.getFileList();

      // 延时刷新
      setTimeout(() => {
        this.getFileList();
      }, 5000);
    },
    // 重命名dialog
    handleRenameDialogVisible(index, raw) {
      this.renameDialogVisible = true;
      console.log("handleRenameDialogVisible.index", index)
      console.log("handleRenameDialogVisible.raw", raw)
      this.id = raw.id;
      if (raw.folderType != 1) { //文件
        this.showRenameFileTypeFlag = true;
        let name = raw.fileName.trim();
        const dotIndex = name.lastIndexOf('.');
        let fileNameWithoutExtension = name.slice(0, dotIndex);
        let fileExtension = name.slice(dotIndex);
        this.renameFile = fileNameWithoutExtension;
        this.renameFileType = fileExtension;
      } else { //文件夹
        this.renameFile = raw.fileName;
        this.showRenameFileTypeFlag = false;
      }
    },
    // 下载文件
    async handleDownload(index, raw) {
      this.initDownloadParam();
      // 开启 doShow()
      this.$refs.popoverDownload.doShow();
      this.downloadFileName = raw.fileName;
      let chunks = 0;
      // 先查询文件分片信息
      const res = await getFileChunksInfo(raw.id);
      console.log("getFileChunksInfo.res:", res)
      if (res.data.code !== 200) {
        this.$modal.msgError("文件删除失败");
        return;
      }
      chunks = res.data.data.chunks;
      console.log(chunks)
      for (let i = 0; i < chunks; i++) {
        const fileRes = await downloadFile(raw.id, i, chunks);
        // 创建Blob对象
        const blob = new Blob([fileRes.data], {type: 'application/octet-stream'});

        this.downloadChunk.push(blob);
        this.downloadFileSize += this.downloadChunk[i].size;
        this.downloadPercent = Math.round((this.downloadFileSize / raw.fileSize) * 100);
        // 检查是否所有分片都已经下载完成
        if (this.downloadChunk.length === chunks) {
          // 所有分片下载完成后，合并分片并触发下载
          this.downloadComplete(this.downloadChunk, raw.fileName);
        }
      }

    },
    // 函数用于合并分片并触发下载完整文件
    downloadComplete(chunks, fileName) {
      let fullBlob = new Blob(chunks, {type: 'application/octet-stream'});
      let link = document.createElement('a');
      link.href = URL.createObjectURL(fullBlob);
      link.download = fileName; // 替换为实际的文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
      this.downloadChunk.length = 0;
      this.$refs.popoverDownload.doClose();
    },
    // 重命名文件夹
    async handleRename() {
      const res = await renameFile({id: this.id, rename: this.renameFile + this.renameFileType})
      console.log("handleRename.res", res)
      if (res.data.code == 200) {
        this.$modal.msgSuccess("修改成功");
      } else {
        this.$modal.msgError("修改失败");
      }
      this.renameDialogVisible = false;
      await this.getFileList();
    },
    // 重命名文件夹关闭操作
    renameFileClose(done) {
      this.renameFile = "";
      this.renameFileType = "";
      this.id = "";
      done();
    },
    // 分享文件
    handleShare(index, raw) {
      console.log("handleShare.index", index)
      console.log("handleShare.raw", raw)
      this.shareDialogVisible = true;
      this.shareFileName = raw.fileName;
      this.id = raw.id;
    },
    // 创建分享链接
    createLink(shareForm) {
      console.log("createLink.shareForm.expired:", this.shareForm.expired)
      console.log("createLink.shareForm.extractMode:", this.shareForm.extractMode)
      console.log("createLink.shareForm.extractCode:", this.shareForm.extractCode)
      const id = this.id;
      const expired = this.shareForm.expired;
      const extractMode = this.shareForm.extractMode;
      const extractCode = this.shareForm.extractCode;

      this.$refs[shareForm].validate((valid) => {
        if (valid) {
          if (this.shareForm.extractMode == 'manual' && this.shareForm.extractCode.trim() == '') {
            this.$modal.msgWarning("请输入提取码");
            return;
          }
          createLink({id, expired, extractMode, extractCode}).then(res => {
            console.log("createLink.res:", res);
            const {data} = res.data;
            this.shareLink = `分享链接：${data.shareLink}\n提取码：${data.extractCode}`;
          }).catch(() => {
          })

        } else {
          return false;
        }
      });
    },
    // 复制分享链接
    copyLink() {
      // 使用this.$refs获取引用的input元素
      const link = this.$refs.shareLink;

      // 调用复制方法，传入input值
      this.$copyText(link.value).then(() => {
        this.$modal.msgSuccess("复制成功");
      }, () => {
        return
      });
    },
    // 单个删除操作
    async handleDelete(index, raw) {
      let ids = [];
      ids.push(raw.id)
      const res = await deleteFile({ids: ids});
      console.log(res.data.code);
      if (res.data.code == 200) {
        this.$modal.msgSuccess("文件删除成功");
      }
      await this.getFileList();
    },
    // 批量删除操作
    async handleBatchDelete() {
      if (this.ids.length <= 0) {
        this.$modal.msgWarning("请先选择文件");
        return;
      }
      const res = await deleteFile({ids: this.ids});
      console.log(res.data.code);
      if (res.data.code == 200) {
        this.$modal.msgSuccess("文件删除成功");
      }
      await this.getFileList();
    },
    // 查询文件列表
    handleSelect(index, indexPath) {
      this.fileCategory = index;
      this.getFileList();
    }
    ,
    // 文件列表 选中操作
    handleSelectionChange(selection) {
      let ids = [];
      console.log("handleSelectionChange.selection:", selection)
      for (let i = 0; i < selection.length; i++) {
        ids.push(selection[i].id);
      }
      this.ids = ids;
      console.log("handleSelectionChange.this.ids:", this.ids)
    },
    // 进入文件夹
    handleEnterFolder(raw) {
      console.log("handleEnterFolder.raw:", raw);
      if (raw.folderType != 1) {
        this.previewContent = {id: raw.id, name: raw.fileName, type: raw.fileType};
        sessionStorage.setItem("previewContent", JSON.stringify(this.previewContent));
        this.previewVisible = true;
        return;
      }
      this.breadcrumbs.push({name: raw.fileName, id: raw.id});
      console.log("this.breadcrumbs:", this.breadcrumbs);
      this.filePid = raw.id;
      this.getFileList();
    },
    // 初始化下载参数
    initDownloadParam() {
      this.downloadPercent = 0;
      this.downloadFileSize = 0;
      this.downloadFileName = "";
    },

  }
}
;
</script>

<style scoped>

.index {
  height: 100%;
}

.operate {
  height: 30px;
}

.operate ul li {
  float: left;
  margin-right: 10px;
  list-style: none;
}

.operate ul:first-child {
  padding-left: 0;
}

.file_list {
  width: 100%;
}

.columnFileName:hover {
  color: blue;
  cursor: pointer;
}

.el-table {
}

.share {
  height: 300px;
  width: 100%;
  margin: 0 auto;
}

::v-deep .el-dialog {
  border-radius: 10px;
  animation: backInLeft; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.3s; /* don't forget to set a duration! */
}

::v-deep .el-dialog__body {
  padding: 0px 20px 20px 10px;
}

</style>
