<template>
  <div>
    <div class="share">
      <div class="file_info">
        <!--文件信息1 start-->
        <div class="file_info_top">
          <ul>
            <li style="padding-right: 10px;">
              <span>
                <el-image :src="file.cover" width="8" height="8" style="height: 24px;width: 24px"/>
              </span>
            </li>
            <li style="padding-left: 0">
              <span>{{ file.name }}</span>
            </li>
            <li style="float:right; margin-right:50px">
              <el-button type="primary" plain size="mini" @click="extractCodeDialogVisible = true">
                <svg-icon icon-class="download"/>
                下载({{ file.convertSize }})
              </el-button>
            </li>
            <li style="float:right;">
              <el-button type="primary" plain size="mini" v-if="false">
                <svg-icon icon-class="forbid"/>
                取消分享
              </el-button>
            </li>
          </ul>
        </div>
        <!--文件信息1 end-->

        <!--文件信息2 start-->
        <div class="file_info_bottom">
          <ul>
            <li>
              <span>
                <svg-icon icon-class="time"></svg-icon>
                {{ file.createTime }}
              </span>
            </li>
            <li>
              <span>
                过期时间：{{ file.expired }}
              </span>
            </li>
          </ul>
        </div>
        <!--文件信息2 end-->
      </div>

      <!--用户信息 start-->
      <div class="share_user_info">
        <!-- 头像 start -->
        <div class="share_avatar">
          <el-avatar :src="avatar"></el-avatar>
        </div>
        <!-- 头像 end -->

        <!-- 用户名 start -->
        <div class="share_avatar" style="padding-left: 0; padding-right: 10px;color: initial">
          <span>{{ username }}</span>
        </div>
        <!-- 用户名 end -->
      </div>
      <!--用户信息 end-->

      <!--文件展示信息 start-->
      <div class="file">
        <div class="file_show">
          <div class="file_center">
            <ul>
              <span>
                <el-image :src="file.cover" style="height: 32px;width: 32px"/>
              </span>
              <li>
                <span>文件大小：{{ file.convertSize }}</span>
              </li>
              <li>
                <!--文件下载progress start-->
                <div v-if="downLoadFlag">
                  <!--文件 名称 进度-->
                  <el-progress :percentage="downloadPercent"></el-progress>
                </div>
                <!--文件下载progress end-->
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--文件展示信息 end-->
    </div>

    <!--填写提取码dialog start-->
    <el-dialog
      title="请输入提取码"
      :visible.sync="extractCodeDialogVisible"
      width="20%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center destroy-on-close
      style="border-radius: 12px"
      @keyup.enter.native="validateExtractCode()"
    >
      <div>
        <el-input v-model="extractCode" style="width: 150px;"></el-input>
        <span slot="footer" class="dialog-footer">
              <el-button @click="extractCodeDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="validateExtractCode()">确 定</el-button>
            </span>
      </div>
    </el-dialog>
    <!--填写提取码dialog end-->

    <!--  底部 start -->
    <div class="share-footer">
      <a href="https://beian.miit.gov.cn/" target="_blank">渝ICP备2023018076号-1</a>
    </div>
    <!--  底部 end -->
  </div>
</template>
<script>
import {getFileChunksInfo, downloadFile, getShareFileInfo, validateExtractCode} from '@/api/file'

export default {
  data() {
    return {
      extractCodeDialogVisible: false,
      extractCode: '',
      downLoadFlag: false,
      avatar: '',
      username: '',
      downloadChunk: [],
      downloadFileSize: 0,
      downloadPercent: 0,
      file: {
        id: '',
        cover: '',
        name: '',
        size: '',
        convertSize: '',
        createTime: '',
        expired: ''
      }
    }
  },
  created() {
    this.getShareFile();

  },
  methods: {
    getLink() {
      const url = window.location.href;
      const link = url.split('/').pop();
      return link;
    },
    getShareFile() {
      getShareFileInfo(this.getLink()).then(res => {
        console.log("getShareFileInfo:", res);
        const {data} = res.data;
        this.file = data
        this.avatar = data.avatar;
        this.username = data.username;
      }).catch(() => {
      })
    },
    validateExtractCode() {
      if (this.extractCode.trim().length <= 0) {
        this.$modal.msgWarning("请输入提取码");
        return;
      }
      validateExtractCode(this.extractCode, this.getLink()).then(res => {
        console.log("validateExtractCode.res:", res)
        if (res.data.code == 901) {
          this.$modal.msgError(res.data.msg);
          return;
        }
        this.downLoadFlag = true;
        this.handleDownload();
        this.extractCode = '';
        this.extractCodeDialogVisible = false;
      }).catch(() => {
      })
    },
    // 下载文件
    async handleDownload() {
      const id = this.file.id;
      const name = this.file.name;
      let chunks = 0;
      // 先查询文件分片信息
      const res = await getFileChunksInfo(id);
      chunks = res.data.data.chunks;
      for (let i = 0; i < chunks; i++) {
        const fileRes = await downloadFile(id, i, chunks);
        // 创建Blob对象
        const blob = new Blob([fileRes.data], {type: 'application/octet-stream'});

        this.downloadChunk.push(blob);
        this.downloadFileSize += this.downloadChunk[i].size;
        this.downloadPercent = Math.round((this.downloadFileSize / this.file.size) * 100);
        console.log("this.downloadFileSize:", this.downloadFileSize)
        console.log("this.downloadPercent:", this.downloadPercent)
        // 检查是否所有分片都已经下载完成
        if (this.downloadChunk.length === chunks) {
          // 所有分片下载完成后，合并分片并触发下载
          this.downloadComplete(this.downloadChunk, name);
          // 下载完成
          this.$modal.msgSuccess("下载完成");
          this.downLoadFlag = false;
        }
      }

    },
    // 函数用于合并分片并触发下载完整文件
    downloadComplete(chunks, fileName) {
      let fullBlob = new Blob(chunks, {type: 'application/octet-stream'});
      let link = document.createElement('a');
      link.href = URL.createObjectURL(fullBlob);
      link.download = fileName; // 替换为实际的文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
      this.downloadChunk.length = 0;
    },
  }
}
</script>
<style scoped>
.share {
  position: absolute;
  height: 500px;
  width: 800px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.19);
  border-radius: 30px;
}

.file_info {
  width: 100%;
  height: 100px;
}

.share_user_info {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
}

.share_avatar {
  float: left;
  padding: 0 10px;
  text-align: center;
}

.share_avatar span {
  color: #a2abc3;

}

.file {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 350px;
}

.file_show {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 700px;
}

.file_center {
  height: 100%;
  width: 500px;
  background-color: #f6f9fd;
}

.file_center ul {
  margin-top: 30px;
}

.file_center ul li {
  padding-top: 20px;
  font-size: 14px;
  color: #666678;
}

.file_info_top {
  height: 60px;
}

.file_info_bottom {
  height: 40px;
  font-size: 14px;
  color: #666666;
}

.file_info_top ul li {
  float: left;
  line-height: 60px;
  padding: 0 20px;
}

.file_info_bottom ul li {
  float: left;
  line-height: 40px;
  padding: 0 20px;
}


.share-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
::v-deep .el-dialog {
  border-radius: 10px;
  animation: backInLeft; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.3s; /* don't forget to set a duration! */
}

::v-deep .el-dialog__body {
  padding: 0px 20px 20px 10px;
}

</style>
