<template>
  <div>
    <div class="communicate">
      <div class="friend">
        <div style="margin: 10px auto;width: 240px">
          <el-autocomplete
            size="small"
            v-model="addFriendName"
            :fetch-suggestions="querySearchAsync"
            placeholder="请输入需要添加好友的用户名"
            @select="handleSelect"
            style="display: inline;width: 100px;"
          >
          </el-autocomplete>
          </div>
        <div class="friend_item" v-for="(item, index) in friendList" :key="index" @click="startChat(item,index)">
          <div class="friend_item_info">
            <div class="friend_head">
              <el-avatar :src="item.friendAvatar"></el-avatar>
            </div>
            <div class="friend_content">
              <div class="friend_name">
                <span>{{ item.friendUsername }}</span>
              </div>
              <div class="friend_msg">
                <span>{{ item.latestMessageContent }}</span>
              </div>
            </div>
            <div class="chat_time">
              <span>{{ item.latestMessageTime }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="chat" v-if="receiverName">
        <div class="chat_head">
          <span>{{ receiverName }}</span>
        </div>
        <div class="chat_body" ref="scoMessage">
          <div v-for="(item,index) in messageList" :key="index"
               :class="item.sender === userCode?'chat_item_right':'chat_item_left'">
            <!--            <el-tooltip effect="light" :content="item.content" :placement="item.name === username?'left':'right'"-->
            <!--                        manual value>-->
            <!--              <el-avatar :src="item.avatar"></el-avatar>-->
            <!--            </el-tooltip>-->
            <el-avatar :src="item.senderAvatar" class="chat_avatar"></el-avatar>
            <pre class="chat_msg">{{ item.content }}</pre>
          </div>
        </div>
        <div class="chat_foot">
          <el-input type="textarea" :rows="5" placeholder="请输入..." v-model="message"
                    @keyup.enter.native="addMsg"></el-input>
          <el-button @click="addMsg" class="send_msg_btn">发送</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getAllFriend, getMessageByUserCode} from "@/api/chat";

export default {
  data() {
    return {
      options4: [
        {
          userCode: "1",
          username: "张三",
          avatar: "",
        }
      ],
      visible: true,
      message: '',
      avatar: '',
      websocket: null,
      // websocketServer: 'ws://10.12.100.30:9001/webSocket/',
      websocketServer: 'wss://www.zhangyujixx.top/webSocket/',
      userCode: '',
      receiverName: '',
      receiverCode: '',
      friendList: [],
      messageList: [],
      addFriendName: ''
    }
  },
  created() {
  },
  mounted() {
    this.createWebsocket();
    this.getFriendList();
  },
  destroyed() {
    this.destroyedWebsocket();
  },
  methods: {
    querySearchAsync(query){
      console.log("remoteMethod",query)
    },
    handleSelect(item) {
      console.log(item);
    },
    createWebsocket() {
      console.log("createWebsocket");
      let userCode = sessionStorage.getItem("userCode");
      this.userCode = userCode;
      const webSocket = new WebSocket(this.websocketServer + userCode);
      console.log("createWebsocket.webSocket:", webSocket);
      webSocket.addEventListener('open', this.handleSocketOpen);
      webSocket.addEventListener('message', this.handleSocketMessage);
      webSocket.addEventListener('close', this.handleSocketClose);
      this.websocket = webSocket;
    },
    destroyedWebsocket() {
      console.log("destroyedWebsocket");
      if (this.websocket) {
        this.websocket.close();
      }
    },
    handleSocketOpen() {
      console.log('WebSocket connection');
    },
    // 获取好友列表
    getFriendList() {
      getAllFriend().then(res => {
        console.log("getFriendList:", res)
        this.friendList = res.data.data;
      }).catch(() => {
      })
    },
    handleSocketMessage(event) {
      let message = event.data;
      let data = JSON.parse(message);
      console.log("handleSocketMessage.message=>", data.content)
      this.messageList.push({
        sender: data.sender,
        senderAvatar: data.senderAvatar,
        receiver: data.receiver,
        content: data.content,
        createTime: Date.now(),
      });
      this.$nextTick(() => {
        this.$refs.scoMessage.scrollTop = this.$refs.scoMessage.scrollHeight;
      });
    },
    handleSocketClose() {
      console.log('WebSocket closed');
    },
    sendMessage(data) {
      const message = { // 构造要发送的消息对象
        sender: this.userCode,
        senderAvatar: this.avatar,
        receiver: this.receiverCode,
        content: data,
        createTime: new Date(),
        sendType: 1
        // 其他消息属性如目标用户、消息类型等
      };
      this.websocket.send(JSON.stringify(message)); // 发送消息
      this.message = '';
    },

    startChat(item, index) {
      this.avatar = sessionStorage.getItem("avatar");
      console.log("startChat.item", item);
      console.log("startChat.index", index);
      this.receiverName = item.friendUsername;
      this.receiverCode = item.friendUserCode;
      // 查询聊天记录
      getMessageByUserCode(item.friendUserCode).then(res => {
        console.log("getMessageByUserCode:", res)
        this.messageList = res.data.data;
        this.$nextTick(() => {
          this.$refs.scoMessage.scrollTop = this.$refs.scoMessage.scrollHeight;
        });
      }).catch(() => {
      })

    },
    addMsg() {
      console.log("addMsg");
      if (this.message.trim() == '') {
        console.log("消息不能为空");
        return;
      }
      this.messageList.push({
        sender: this.userCode,
        senderAvatar: this.avatar,
        receiver: this.receiverCode,
        content: this.message,
        createTime: new Date(),
      });
      this.$nextTick(() => {
        this.$refs.scoMessage.scrollTop = this.$refs.scoMessage.scrollHeight;
      });
      this.sendMessage(this.message);
    }
  }
}
</script>

<style scoped lang="less">
.communicate {
  margin: auto;
  height: 700px;
  width: 1000px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .friend {
    padding: 0;
    margin: 0;
    float: left;
    width: 300px;
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE 和 Edge */
    scrollbar-width: none; /* Firefox */

    .friend_item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px auto;
      height: 60px;
      width: 80%;
      border-bottom: #e7e7e7 solid 1px;

      .friend_item_info {
        width: 100%;
        height: 40px;

        .friend_head {
          float: left;
          width: 40px;
          height: 100%;
        }

        .friend_content {
          float: left;
          margin-left: 5px;
          width: 60%;
          height: 100%;
          line-height: 20px;
          text-align: left;

          .friend_name {
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 隐藏超出部分 */
            text-overflow: ellipsis; /* 超出部分显示为省略号 */
          }

          .friend_msg {
            font-size: 13px;
            color: #999999;
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 隐藏超出部分 */
            text-overflow: ellipsis; /* 超出部分显示为省略号 */
          }
        }

        .chat_time {
          float: left;
          margin-left: 5px;
          width: 10%;
          height: 100%;
          line-height: 40px;
        }
      }

    }
  }

  .friend_item:hover {
    cursor: pointer;
    animation: headShake;
    animation-duration: 2s;
  }

  .chat {
    float: left;
    width: 700px;
    height: 100%;

    .chat_head {
      display: flex;
      align-items: center;
      width: 100%;
      height: 80px;
      text-align: left;
      font-size: 18px;
      border-bottom: #e7e7e7 solid 1px;
      background-color: #f5f5f5;

      span {
        margin-left: 20px;
      }
    }

    .chat_body {
      width: 100%;
      height: 503px;
      background-color: #f5f5f5;
      overflow-y: auto;
      -ms-overflow-style: none; /* IE 和 Edge */
      scrollbar-width: none; /* Firefox */

      .chat_item_left {
        margin: 15px 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: left;
        width: 700px;

        .chat_avatar {
          margin-left: 10px;
        }

        .chat_msg {
          position: absolute;
          left: 60px;
          user-select: text;
          font-size: 14px;
          font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
        }
      }

      .chat_item_right {
        margin: 15px 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: right;
        width: 700px;

        .chat_avatar {
          margin-right: 10px;
        }

        .chat_msg {
          position: absolute;
          right: 60px;
          user-select: text;
          font-size: 14px;
          font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
        }
      }

    }

    .chat_foot {
      position: relative;
      width: 100%;
      height: 116px;
      z-index: 100000;

      .send_msg_btn {
        z-index: 999;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
}


</style>
