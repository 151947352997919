<template>
  <div class="upload_component">
    <div>
      <el-upload class="upload-demo" drag action="" :auto-upload="true" :before-upload="beforeUpload"
                 :on-change="handleChange">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <el-button @click="startUpload()">开始上传</el-button>
    </div>
    <div class="show_file" v-if="file">
      <!--文件 名称 进度-->
      <h1>文件名：{{ file.name }}</h1>
      <el-progress :percentage="uploadPercent"></el-progress>
    </div>
  </div>
</template>
<script>

import {uploadFile, mergeFile} from "@/api/file";
import SparkMD5 from 'spark-md5';

export default {
  data() {
    return {
      sharedData:[],
      file: null,
      fileId: "",
      fileMd5: "",
      chunkSize: 1024 * 1024 * 5, // 分片大小，这里设置为2MB
      chunks: [],
      currentChunk: 0,
      uploadTaskToken: null,
      uploadFileSize: 0,
      uploadPercent: 0, // 上传进度百分比
      uploadStatus: 200

    }
  },
  methods: {
    // 分片
    beforeUpload(file) {
      console.log("选择的文件" + file.size)
      this.file = file;
      this.chunks = [];
      console.log("this.file " + this.file.name)
      for (let i = 0; i < file.size; i = i + this.chunkSize) {
        console.log("开始分片" + i)
        const end = Math.min(i + this.chunkSize, file.size);
        const chunk = file.slice(i, end);
        if (i === 0) {
          console.log("0000")
        }
        this.chunks.push(chunk);
      }
      console.log("this.chunks" + this.chunks.length)
      return false; //阻止上传
    },
    // 清空分片
    handleChange(file) {
      // 文件选择后清空原有的chunks，准备新的分片上传
      console.log("handleChange文件状态" + file.status)
      if (file.status !== 'ready') return;
      this.chunks = [];

    },
    // 上传分片
    async uploadChunk(chunk, index) {
      // 进行md5计算
      let spark = new SparkMD5.ArrayBuffer();
      let fileReader = new FileReader();
      fileReader.onload = function (e) {
        spark.append(e.target.result); // 添加分片的ArrayBuffer到SparkMD5实例
      };
      let chunkMD5 = spark.end(); // 计算当前分片的MD5值
      console.log("当前分片的md5：" + chunkMD5)
      // 这里仅作示例，实际项目中应根据后端API进行调整
      let path = '';
      let parentName = '';
      this.sharedData = JSON.parse(sessionStorage.getItem("breadcrumbs"));
      console.log("上传组件sessionStorage",this.sharedData)
      if (this.sharedData.length > 0) {
        for (let i = 0; i < this.sharedData.length; i++) {
          path += (this.sharedData[i].id + '/');
          parentName += (this.sharedData[i].name + '/');
        }
      }

      const formData = new FormData();
      formData.append('fileId', this.fileId);
      formData.append('chunk', chunk); // chunk是从原始文件切片得到的Blob对象
      formData.append('chunkIndex', index);
      formData.append('totalChunks', this.chunks.length);
      formData.append('fileName', this.file.name);
      formData.append('fileMd5', chunkMD5);
      formData.append('filePath', path);
      formData.append('parentName', parentName);
      try {
        const res = await uploadFile(formData);

        this.uploadFileSize += chunk.size;
        this.uploadPercent = Math.round((this.uploadFileSize / this.file.size) * 100);
        console.log(`上传分片返回的结果：${res.data.code}`)
        if (res.data.code != 200) {
          this.$modal.msgError("文件上传失败");
          this.uploadStatus = res.data.code;
          return;
        }
        console.log(`正在上传第${index}个分片`)
        // 第一个分片 需要返回fileId
        if (index === 0) {
          console.log(`第一个分片返回的fileId：${res.data.data}`)
          this.fileId = res.data.msg
        }

        if (index === this.chunks.length - 1) {
          // 最后一块上传成功，通知后端合并文件
          console.log("最后一块上传成功，通知后端合并文件fileId:", this.fileId)
          mergeFile({id: this.fileId, fileName: this.file.name, filePath: path, parentName: parentName});
          const message = {content: 1};
          this.$emit('child-to-parent', message);
        }
      } catch (error) {
        console.error('Chunk upload failed:', error);
      }
    },
    // 开始上传
    async startUpload() {
      // this.beforeUpload(this.file)
      console.log("开始上传");
      console.log("this.sharedData", this.sharedData)
      if (!this.file || !this.chunks.length) {
        this.$modal.notifyWarning("请选择要上传的文件");
        return;
      }

      let startTime = new Date().getTime();

      for (let i = 0; i < this.chunks.length; i++) {
        console.log(i)
        await this.uploadChunk(this.chunks[i], i);
        console.log("uploadChunk上传状态：" + this.uploadStatus)
        if (this.uploadStatus != 200) return;
        this.currentChunk++;
      }
      let endTime = new Date().getTime();
      console.log("上传文件所用时间:", endTime - startTime)
    }
  }
}
</script>
<style>
.upload_component {
  margin: auto;
  width: 360px;
}

/deep/ .el-progress__text {
  color: white;
}

.show_file {
  border: aqua 1px solid;
}

.show_file h1 {
  float: left;
  font-weight: 400;
  font-size: 14px;
  padding-left: 10px;
}

</style>
