import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Login from '../views/login/Login.vue'
import WxCode from '../components/login/WxCode.vue'
import Account from '../components/login/Account.vue'
import Share from '../views/share/Share.vue'
import FileList from "@/views/fileList/FileList.vue";
import Communicate from '@/views/communicate/Communicate.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    name: 'Index',
    component: Index,
    meta: {title: 'FileManager'},
    children: [
      {
        path: '/index',
        name: 'fileList',
        component: FileList,
      },
      {
        path: '/communicate',
        name: 'communicate',
        component: Communicate,
      }
    ]
  },
  {
    path: '/share/*',
    name: 'Share',
    component: Share,
    meta: {title: 'FileShare'}
  },
  {
    path: '/',
    name: 'login',
    component: Login,
    children: [
      {
        path: '/account',
        name: 'account',
        component: Account
      },
      {
        path: '/wxCode',
        name: 'wxCode',
        component: WxCode
      },

    ]
  },
  {
    path: '/uploadView',
    name: 'uploadView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Upload/UploadView.vue')
  },
  {
    path: '/404',
    name: 'NotFound',
    meta: {
      title: 'Page not found',
      isLogin: false
    },
    component: () => import('@/assets/404/404.vue')
  },
  // 所有未定义路由，全部重定向到404页
  {
    path: '*',
    redirect: '/404'
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 如果应用启动时需要重定向到默认路由，可以使用beforeEach钩子
router.beforeEach((to, from, next) => {
  // 检查是否需要重定向到默认路由
  if (to.path === '' || to.path === '/') {
    next({name: 'wxCode'}); // 替换为你的默认路由名称
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  document.title = to.meta.title || 'FileManager'; // 根据路由元信息设置标题，如果没有提供则使用默认标题
});

export default router
