<template>
  <div>
    <div class="login_form">
      <!--  登录框左边 start -->
      <div class="login_form_left">
        <svg-icon icon-class="login_left" style="width: 100%;height: 100%"></svg-icon>
      </div>
      <!--  登录框左边 end -->

      <!--  登录框右边 start -->
      <div class="login_form_right">
        <div class="login_options">
          <nav>
            <router-link to="/wxCode">扫码登录</router-link>
            |
            <router-link to="/account">账号登录</router-link>
          </nav>
        </div>
        <!--  登录选项路由区域 start -->
        <div class="login">
          <router-view/>
        </div>
        <!--  登录选项路由区域 end -->

        <div class="login_qq">
          <ul>
            <li>快捷登录</li>
            <li @click="handleQQLogin">
              <svg-icon icon-class="QQ" class="shortcut_login"></svg-icon>
            </li>
            <li>
              <svg-icon icon-class="Wechat" class="shortcut_login"></svg-icon>
            </li>
            <li>
              <svg-icon icon-class="Weibo" class="shortcut_login"></svg-icon>
            </li>
            <li @click="toRegister()" v-if="register">立即注册</li>
          </ul>
        </div>
      </div>
      <!--  登录框右边 end -->

    </div>

    <!--  底部 start -->
    <div class="el-login-footer">
      <a href="https://beian.miit.gov.cn/" target="_blank">渝ICP备2023018076号-1</a>
    </div>
    <!--  底部 end -->
  </div>
</template>
<script>

export default {
  data() {
    return {
      register: false,
    }
  },
  created() {
  },
  methods: {
    handleQQLogin() {
      console.log("login");
      //以下为按钮点击事件的逻辑。注意这里要重新打开窗口
      //否则后面跳转到QQ登录，授权页面时会直接缩小当前浏览器的窗口，而不是打开新窗口
      var A=window.open("oauth/index.php","TencentLogin",
        "width=450,height=320,menubar=0,scrollbars=1,resizable=1,status=1,titlebar=0,toolbar=0,location=1");
    },
    toRegister(){
      this.register = false;
      this.$router.push("/register");
    }
  }
}
</script>
<style scoped>
.login_form {
  position: absolute;
  height: 400px;
  width: 800px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.19);
  border-radius: 5%;
}

.login_form_left {
  float: left;
  width: 350px;
  height: 100%;
}

.login_form_right {
  position: relative;
  float: left;
  width: 450px;
  height: 100%;
}

.login_options {
  width: 100%;
  height: 40px;
}

nav {
  padding: 10px 30px;
}

nav a {
  color: rgb(133, 133, 133);
  font-weight: 400;
  text-decoration: none;
}

nav a.router-link-exact-active {
  color: black;
}

.login {
  height: 300px;
}

.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}

.login_qq {
  position: absolute;
  top: 85%;
  left: 10%;
  height: 30px;
}

.login_qq ul {
  margin: 0;
  padding: 0;
}

.login_qq ul li {
  float: left;
  list-style: none;
  line-height: 30px;
  color: #858585;
  font-size: 14px;
  padding-left: 20px;
}

.login_qq ul li:hover {
  cursor: pointer;
}

.shortcut_login {
  font-size: 28px;
  color: initial;
}
</style>
