<template>
  <div>
    <el-form :model="loginForm" :rules="loginRules" ref="loginForm" class="loginForm">
      <!--账号输入框 start-->
      <el-form-item prop="username">
        <el-input v-model="loginForm.username" placeholder="请输入您的账号">
          <svg-icon slot="prefix" icon-class="myUser" class="el-input__icon input-icon"/>
        </el-input>
      </el-form-item>
      <!--账号输入框 end-->

      <!--密码输入框 start-->
      <el-form-item prop="password">
        <el-input v-model="loginForm.password" type="password"
                  placeholder="请输入您的密码">
          <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon"/>
        </el-input>
      </el-form-item>
      <!--密码输入框 end-->

      <!--验证码输入框 start-->
      <el-form-item prop="code">
        <el-input v-model="loginForm.code" style="width: 200px;float: left"
                  placeholder="请输入验证码" @keyup.enter.native="handleLogin('loginForm')">
          <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon"/>
        </el-input>
        <el-button type="primary" @click="handleLogin('loginForm')" style="float: right;margin-right: 10px">
          <span v-if="!loading">起 飞</span>
          <span v-else>芜 湖...</span>
        </el-button>
      </el-form-item>
      <!--验证码输入框 end-->

      <!--验证码图片 start-->
      <el-form-item>
        <img :src="codeUrl" @click="getCode" class="login-code-img"/>
        <span style="margin-right: 10px">忘记密码?</span>
      </el-form-item>
      <!--验证码图片 end-->
    </el-form>
  </div>
</template>

<script>
import store from "@/store";
import {getCodeImg, login} from '@/api/login'
import {setToken} from '@/utils/auth'

export default {
  name: "Login",
  data() {
    return {
      codeUrl: "",
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: ""
      },
      loginRules: {
        username: [
          {required: true, trigger: "blur", message: "请输入您的账号"}
        ],
        password: [
          {required: true, trigger: "blur", message: "请输入您的密码"}
        ],
        code: [{required: true, trigger: "blur", message: "请输入验证码"}]
      },
      loading: false,
      // 验证码开关
      captchaEnabled: true,
      // 注册开关
      register: false,
      redirect: undefined,
      validFlag: false
    };
  },
  watch: {},
  created() {
    this.getCode();
  },
  methods: {
    getCode() {
      console.log("发送验证码请求")
      getCodeImg().then(res => {
        console.log(res)
        this.codeUrl = "data:image/gif;base64," + res.data.img;
        this.loginForm.uuid = res.data.uuid;
      }).catch(() => {
      });
    },
    getCookie() {

    },
    async handleLogin() {
      this.$refs.loginForm.validate(valid => {
        this.validFlag = valid;
      });
      if (this.validFlag) {
        this.loading = false;
        login(this.loginForm).then(res => {
          console.log("handleLogin.res", res)
          if (res.data.code == 200) {
            // 发请求
            store.dispatch('GetInfo').then(()=>{
              this.$router.push({path: '/index'})
            }).catch(()=>{});
            console.log("handleLogin.res", res)
            // login success
            // setToken
            setToken(res.data.data.access_token);
          } else {
            this.$modal.msgError("验证码不正确");
            this.getCode();
          }
        }).catch(() => {
          this.getCode();return;
        });

      }
    }
  }
};
</script>
<style>
.loginForm {
  margin: 20px 65px 0 65px;
  padding: 10px;
  width: 300px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.19);
  border-radius: 5%;
  animation: zoomIn; /* referring directly to the animation's @keyframe declaration */
  animation-duration: .5s; /* don't forget to set a duration! */
}

.login-code-img {
  float: left;
  height: 38px;
  width: 150px;
  draggable: false;
  user-drag: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  user-select: none;
}

.input-icon {
  height: 39px;
  width: 14px;
  margin-left: 2px;
}

</style>
