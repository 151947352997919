import request from '@/utils/request'

// 获取好友列表
export function getAllFriend() {
  return request({
    url: '/auth/userFriend/getAll',
    method: 'get',
  })
}


// 获取聊天记录
export function getMessageByUserCode(userCode) {
  return request({
    url: '/auth/userMessage/getMessageByUserCode/'+userCode,
    method: 'get',
  })
}
