import request from '@/utils/request'

// 获取用户信息
export function getUserInfo() {
  return request({
    url: '/auth/user/admin/getInfo',
    method: 'get'
  })
}


// 修改密码
export function editPassword(data) {
  return request({
    url: '/auth/user/admin/editPassword',
    method: 'put',
    data: data
  })
}

// 上传头像
export function uploadAvatar(data) {
  return request({
    url: '/auth/user/admin/updateAvatar',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: data
  })
}
