import {getUserInfo} from '@/api/service'
import {getToken, setToken, setExpiresIn, removeToken} from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    id: '',
    name: '',
    avatar: '',
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      console.log('SET_NAME', name)
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      console.log('SET_AVATAR', avatar)
      state.avatar = avatar
    }
  },

  actions: {
    // 获取用户信息
    GetInfo({commit, state}) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(res => {
          const {data} = res.data
          console.log("获取用户信息",data)
          const avatar = (data.userAvatar == "" || data.userAvatar == null) ? require("@/assets/images/profile.jpg") : data.userAvatar;
          commit('SET_NAME', data.username)
          commit('SET_AVATAR', avatar)
          sessionStorage.setItem("avatar", avatar)
          sessionStorage.setItem("username", data.username)
          sessionStorage.setItem("userCode", data.userCode)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default user
