<template>
  <div class="upload">
    <el-upload
      class="avatar-uploader"
      action="https://jsonplaceholder.typicode.com/posts/"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload">
      <div>
        <div>
          <span>头像</span>
        </div>
        <div>
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </div>
      </div>

    </el-upload>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: ''
    };
  },
  created() {
    const avatar = sessionStorage.getItem("avatar") || require("@/assets/images/profile.jpg");
    this.imageUrl = avatar;
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    }
  }
}
</script>


<style scoped lang="less">
.upload {
  height: 40px;
  width: 100%;
}

.avatar-uploader {
  height: 40px;
  width: 100%;
  border: 1px dashed #d9d9d9;
  border-radius: 40px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.avatar {
  width: 40px;
  height: 40px;
  display: block;
}
</style>
