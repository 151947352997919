import request from '@/utils/request'


// 上传文件
export function uploadFile(data) {
  return request({
    url: '/auth/file/operate/uploadFile',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: data
  })
}

// 下载文件
export function downloadFile(fileId, chunkIndex, totalChunks) {
  return request({
    url: '/auth/file/operate/downloadFile/' + fileId + '/chunkIndex/' + chunkIndex + '/totalChunks/' + totalChunks,
    method: 'get',
    responseType: 'arraybuffer',
    timeout: 30000
  })
}

// 获取文件分片信息
export function getFileChunksInfo(fileId) {
  return request({
    url: '/auth/file/operate/getFileChunksInfo/' + fileId,
    method: 'get'
  })
}

// 合并文件
export function mergeFile(data) {
  return request({
    url: '/auth/file/operate/mergeFile',
    method: 'post',
    data: data
  })
}

// 查询文件列表
export function getFileList(fileCategory, filePid) {

  return request({
    url: `/auth/file/operate/getFileList?fileCategory=${fileCategory}&filePid=${filePid}`,
    method: 'get'
  })
}

// 新增文件夹
export function mkdir(data) {
  return request({
    url: '/auth/file/operate/mkdir',
    method: 'post',
    data: data
  })
}

// 删除文件/文件夹
export function deleteFile(data) {
  return request({
    url: '/auth/file/operate/deleteFile',
    method: 'delete',
    data: data
  })
}

// 重命名文件/文件夹
export function renameFile(data) {
  return request({
    url: '/auth/file/operate/renameFile',
    method: 'post',
    data: JSON.stringify(data)
  })
}

// 分享文件 创建链接
export function createLink(data) {
  return request({
    url: '/auth/file/operate/createLink',
    method: 'post',
    data: data
  })
}

// 校验提取码
export function validateExtractCode(extractCode, link) {
  return request({
    url: '/auth/file/operate/validateExtractCode?extractCode=' + extractCode + '&link=' + link,
    method: 'get'
  })
}

// 获取分享文件的信息
export function getShareFileInfo(link) {
  return request({
    url: '/auth/file/operate/getShareFileInfo?link=' + link,
    method: 'get'
  })
}



