<template>
  <div>
    <!-- PDF -->
    <div v-if="isPdf" style="max-height:100%">
      <!-- 根据文件类型选择预览方式 -->
      <pdf
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        ref="pdf"
        :src="previewUrl"
        :page="currentFilePage"
        :page-count="totalPages"
        @num-pages="onNumPages"
        @page-loaded="onPageLoaded"
        @error="onError"
      ></pdf>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="currentFilePage"
        :page-size="1"
        :total="totalPages"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>

    <!-- 图片 -->
    <div v-if="isImage">
      <!-- 根据文件类型选择预览方式 -->
      <el-image :src="previewUrl"
                v-loading="loading"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)">

      </el-image>
    </div>

    <!-- 图片 -->
    <div v-if="isTxt">
      <!-- 根据文件类型选择预览方式 -->
      {{ txtContent }}
    </div>

    <!-- 视频 -->
    <div v-if="isVideo">
      <!-- 根据文件类型选择预览方式 -->
      <video :src="previewUrl" controls style="max-width: 100%;max-height: 100%"></video>
    </div>
  </div>
</template>
<script>
import pdf from 'vue-pdf'
import {getFileChunksInfo, downloadFile} from '@/api/file'
// 在您的Vue组件中
export default {
  components: {
    pdf
  },
  data() {
    return {
      content: {},
      currentFilePage: 1,
      totalPages: 0,
      isImage: false,
      isVideo: false,
      isPdf: false,
      isTxt: false,
      txtContent: '',
      previewUrl: '',
      loading: true
    }
  },
  created() {
    this.content = JSON.parse(sessionStorage.getItem('previewContent'));
    console.log("created.this.content: ", this.content);
  },
  mounted() {
    this.downloadSource()
  },
  beforeDestroy() {
    console.log("beforeDestroy")
  },
  destroyed() {
    console.log("destroyed")
  },
  methods: {
    handleCurrentChange(pageNum) {
      console.log("handleCurrentChange=", pageNum)
      this.currentFilePage = pageNum;
    },
    onPageLoaded(pageNumber) {
      console.log('Page loaded:', pageNumber);
      this.currentFilePage = pageNumber;
      console.log("this.currentPage", this.currentFilePage)
    },
    onError(error) {
      console.error('PDF rendering error:', error);
    },
    onNumPages(pages) {
      this.totalPages = pages;
      console.log("pages", pages);
      console.log("totalPages", this.totalPages);
    },
    // 下载资源
    async downloadSource() {
      let type = 0;
      type = this.content.type;
      console.log("downloadSource.type:", type);
      if (type == 2) { // 视频
        this.isVideo = true;
      } else if (type == 4) { // pdf
        this.isPdf = true;
      } else if (type == 3) { // 图片
        this.isImage = true;
      } else if (type == 7) { // txt
        this.isTxt = true;
      }
      if (this.content.id == undefined) return;
      // 先获取分片信息
      let totalChunks = 0;
      let id = this.content.id;
      await getFileChunksInfo(id).then(res => {
        console.log("downloadSource.getFileChunksInfo.res:", res);
        totalChunks = res.data.data.chunks;
      }).catch(error => {
      })
      let var1 = [];
      for (let i = 0; i < totalChunks; i++) {
        const fileRes = await downloadFile(id, i, totalChunks);
        // 创建Blob对象
        const blob = new Blob([fileRes.data], {type: 'application/octet-stream'});
        var1.push(blob);
      }
      let var2 = new Blob(var1, {type: 'application/octet-stream'});
      if (type == 2 || type == 3) { //图片
        this.loading = false;
        this.previewUrl = URL.createObjectURL(var2);
        return;
      }
      if (type == 7) { // txt
        this.txtContent = await var2.text();
        return;
      }
      const arrayBuffer = await new Response(var2).arrayBuffer();
      console.log("arrayBuffer", arrayBuffer);
      this.loading = false;
      this.previewUrl = new Uint8Array(arrayBuffer);
    }
  },
}

</script>

<style scoped>

</style>
