<template>
  <div>
    <el-form :model="loginForm" :rules="loginRules" ref="loginForm" class="loginForm">
      <!--微信公众号二维码 start-->
      <el-image :src="require('@/assets/weixin.jpg')" style="height: 170px;"></el-image>
      <!--微信公众号二维码 end-->

      <!--验证码输入框 start-->
      <el-form-item prop="code">
        <el-input v-model="loginForm.code" style="width: 200px;float: left"
                  placeholder="请输入验证码"
                  @keyup.enter.native="handleLogin('loginForm')">
          <svg-icon slot="prefix" icon-class="valiCode" class="el-input__icon input-icon"/>
        </el-input>
        <el-button type="primary" @click="handleLogin('loginForm')" class="to-login">
          <span v-if="!loading">起 飞</span>
          <span v-else>芜 湖...</span>
        </el-button>
      </el-form-item>
      <!--验证码输入框 end-->
      <span style="font-size: 16px;color: #b29385;font-style: italic">tips</span>
      <span style="font-size: 14px;color: #b29385">:扫描二维码并[关注]后，发送[验证码]关键字</span>
    </el-form>
  </div>
</template>

<script>
import {wxLogin} from "@/api/login";
import {setToken} from "@/utils/auth";
import store from "@/store";

export default {
  name: "Login",
  data() {
    return {
      codeUrl: "",
      loginForm: {
        code: "",
      },
      loginRules: {
        code: [{required: true, trigger: "blur", message: "请输入验证码"}]
      },
      validFlag: false,
      loading: false,
    };
  },

  methods: {
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        this.validFlag = valid;
      });
      if (this.validFlag) {
        wxLogin({validateCode: this.loginForm.code}).then(res => {
          console.log("wxLogin.res", res)
          if (res.data.code == 200) {
            // 发请求
            store.dispatch('GetInfo').then(() => {
              this.$router.push({path: '/index'})
            }).catch(() => {
            });
            console.log("handleLogin.res", res)
            // login success
            // setToken
            setToken(res.data.data.access_token);
          }
        }).catch((e) => {
        })
      }
    }
  }
};
</script>
<style scoped lang="less">
.loginForm {
  margin: 20px 65px 0 65px;
  padding: 10px;
  width: 300px;
  height: 250px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.19);
  border-radius: 5%;
  animation: zoomIn; /* referring directly to the animation's @keyframe declaration */
  animation-duration: .5s; /* don't forget to set a duration! */
  .to-login {
    float: right;
    margin-right: 10px;
  }
}

.login-code-img {
  float: left;
  height: 38px;
  width: 150px;
  draggable: false;
  user-drag: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  user-select: none;
}

.input-icon {
  height: 39px;
  width: 14px;
  margin-left: 2px;
}

</style>
