<template>
  <div class="index">
    <el-container>
      <el-header style=" color: blue">

        <div class="logo">
          <div class="logo_info">
            <svg-icon icon-class="my_logo" class="my_logo"></svg-icon>
          </div>
          <div class="logo_info">
            <span class="logo_info_font">File Assistant</span>
          </div>
        </div>
        <!-- 右侧用户信息 start -->
        <div class="user_info">

          <!-- 头像 start -->
          <div class="avatar">
            <el-popover
              ref="popover_avatar"
              placement="bottom"
              width="150"
              trigger="hover"
            >
              <div class="editUserInfo">
                <div class="edit_item" @click="handleEditUserInfoDialog">
                  <i>
                    <svg-icon icon-class="edit"></svg-icon>
                  </i>
                  <span>修改资料</span>
                </div>
                <div class="edit_item" @click="editPasswordDialog = true">
                  <i>
                    <svg-icon icon-class="password"></svg-icon>
                  </i>
                  <span>修改密码</span>
                </div>
                <div class="edit_item" @click="logout()">
                  <i>
                    <svg-icon icon-class="shut_down"></svg-icon>
                  </i>
                  <span>退出登录</span>
                </div>
              </div>
            </el-popover>
            <el-avatar :src="avatar" v-popover:popover_avatar></el-avatar>
          </div>
          <!-- 头像 end -->

          <!-- 用户名 start -->
          <div class="avatar" style="padding-left: 0; padding-right: 10px;color: initial">
            <span>{{ username }}</span>
          </div>
          <!-- 用户名 end -->

        </div>
        <!-- 右侧用户信息 end -->
      </el-header>
      <el-container>
        <!--导航栏菜单开始-->
        <el-aside width="150px">
          <el-menu
            router
            :default-active="$route.path"
          >
            <el-menu-item index="/index">
              <i class="el-icon-menu"></i>
              <span slot="title">文件</span>
            </el-menu-item>
            <el-menu-item index="/communicate">
              <i class="el-icon-document"></i>
              <span slot="title">通讯</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <!--导航栏菜单结束-->

        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>


    <!--修改用户信息dialog start-->
    <el-dialog
      :visible.sync="editUserInfoDialog"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center destroy-on-close style="border-radius: 20px">
      <div>
        <el-form>
          <el-form-item>
            <div style="float: left;margin-left: 50px">
              <span>头像</span>
            </div>
            <div style="float: right;margin-right: 50px;height: 40px;width: 40px;">
              <el-upload drag action="" :auto-upload="true" :before-upload="beforeAvatarUpload"
                         :on-change="handleAvatarChange">
                <img v-if="avatar" :src="avatar" style="height: 40px;width: 40px"/>
              </el-upload>
              <!--              <el-avatar :src="avatar"></el-avatar>-->
            </div>
          </el-form-item>
          <el-form-item>
            <div style="float: left;margin-left: 50px">
              <span>用户名</span>
            </div>
            <div style="float: right;margin-right: 50px">
              <el-input v-model="editUsername" placeholder="请输入用户名" style="width: 100px"></el-input>
            </div>
          </el-form-item>
          <el-form-item style="display: flex; justify-content: center;align-items: center">
            <el-button @click="editUserInfoDialog = false">取 消</el-button>
            <el-button type="primary" @click="handleEditUserInfo()">确 定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!--修改用户信息dialog end-->


    <!--修改密码dialog start-->
    <el-dialog
      title="修改密码"
      :visible.sync="editPasswordDialog"
      width="20%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center destroy-on-close style="border-radius: 20px">
      <el-form :model="editPassword" :rules="editPasswordRules" ref="editPassword" class="editPassword">
        <!--旧密码 start-->
        <el-form-item prop="oldPassword" label="旧密码">
          <el-input v-model="editPassword.oldPassword" type="password"
                    placeholder="请输入您的旧密码">
            <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon"/>
          </el-input>
        </el-form-item>
        <!--旧密码 end-->

        <!--新密码 start-->
        <el-form-item prop="confirmPassword" label="新密码">
          <el-input v-model="editPassword.confirmPassword" type="password"
                    placeholder="请输入您的新密码">
            <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon"/>
          </el-input>
        </el-form-item>
        <!--新密码 end-->

        <!--确认密码 start-->
        <el-form-item prop="newPassword" label="确认密码">
          <el-input v-model="editPassword.newPassword" type="password"
                    placeholder="请确认您的新密码">
            <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon"/>
          </el-input>
        </el-form-item>
        <!--确认密码 end-->

        <el-form-item style="display: flex; justify-content: center;align-items: center">
          <el-button @click="editPasswordDialog = false">取 消</el-button>
          <el-button type="primary" @click="handleEditPassword()">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--修改密码dialog end-->
  </div>
</template>
<script>
import {removeToken, setToken} from '@/utils/auth'
import {logout} from '@/api/login'
import {editPassword, uploadAvatar} from '@/api/service'
import UploadAvatar from "@/components/Upload/UploadAvatar.vue";

export default {
  components: {UploadAvatar},
  data() {
    return {
      avatar: "", // 头像
      username: "", // 用户名
      editUsername: "",
      editUserInfoDialog: false, // 修改用户信息
      editPasswordDialog: false, // 修改密码
      editPassword: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      editPasswordRules: {
        oldPassword: [
          {required: true, trigger: "blur", message: "请输入您的原始密码"}
        ],
        newPassword: [
          {required: true, trigger: "blur", message: "请输入您的新密码"}
        ],
        confirmPassword: [{required: true, trigger: "blur", message: "请确认您的新密码"}]
      },
    };
  },
  created() {
  },
  mounted() {
    this.setUserInfo();
    console.log(this.$route.name)
  },
  methods: {
    // 设置用户信息
    setUserInfo() {
      this.avatar = sessionStorage.getItem("avatar")
      this.username = sessionStorage.getItem("username")
      console.log("setUserInfo.avatar:", this.avatar);
      console.log("setUserInfo.username:", this.username);
    },
    // 登出
    logout() {
      console.log("登出")
      logout().then(res => {
        removeToken();
        sessionStorage.clear();
      }).catch(() => {
      });
      this.$router.push({path: '/account'})
    },
    // 修改用户信息弹窗
    handleEditUserInfoDialog() {
      this.editUserInfoDialog = true;
      this.editUsername = this.username;
    },
    handleEditUserInfo() {

    },
    // 修改密码
    handleEditPassword() {
      console.log("handleEditPassword", this.editPassword)
      editPassword(this.editPassword).then(res => {
        console.log("handleEditPassword.res:", res.data)
        if (res.data.code == 200) {
          // 密码修改成功
          // 更新token
          setToken(res.data.data.access_token)
          this.$modal.msgSuccess("修改密码成功");
          this.editPasswordDialog = false;
        } else {
          this.$modal.msgError(res.data.data.msg);
        }
      }).catch(() => {
      })
    },
    // 头像上传前
    beforeAvatarUpload(file) {
      console.log("头像上传前")
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$modal.msgError('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$modal.msgError('上传头像图片大小不能超过 2MB!');
      }
      const formData = new FormData();
      formData.append('avatar', file); // chunk是从原始文件切片得到的Blob对象
      uploadAvatar(formData).then(res => {
        if (res.data.code == 200) {
          console.log("beforeAvatarUpload.res:",res.data.data.url)
          this.avatar = res.data.data.url;
          sessionStorage.setItem("avatar", this.avatar);
          this.$modal.msgSuccess("更新头像成功");
        } else {
          this.$modal.msgError("更新头像失败");
        }
      }).catch(() => {
      })
      return false; //阻止上传
    },
    // 文件选择发生改变
    handleAvatarChange(file) {
      // 文件选择后清空原有的chunks，准备新的分片上传
      console.log("文件选择发生改变" + file.status)
      if (file.status !== 'ready') return;

    },
  }
}
;
</script>

<style lang="less" scoped>
.logo {
  float: left;
  width: 200px;
  height: 60px;
  align-items: center;
  justify-content: center;
}

.my_logo {
  font-size: 60px;
}

.logo_info {
  float: left;
  line-height: 60px;
  font-size: 18px;

}

.logo_info_font {
  letter-spacing: 1px; /* 这里的数值可以根据需要调整，单位可以是px、em、rem等 */
  color: #6e90e8;
  /* 添加阴影效果，模拟3D立体 */
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3), /* 左下阴影 */ -1px -1px 0 rgba(0, 0, 0, 0.1), /* 右上阴影 */ 1px -1px 0 rgba(0, 0, 0, 0.1), /* 右下阴影 */ -1px 1px 0 rgba(0, 0, 0, 0.3); /* 左上阴影 */

  /* 您还可以使用transform属性旋转文字来增强3D效果 */
  transform: perspective(1000px) rotateX(10deg);
}

.index, el-container {
  height: 100%;
}


.user_info {
  display: flex;
  float: right;
  align-items: center;
  justify-content: center;
  height: 100%;

}

.user_info .avatar {
  padding: 0 10px;
  text-align: center;
}

.edit_item {
  margin: 10px auto 10px auto;
  width: 80px;
  font-size: 14px;
}

.edit_item:hover {
  cursor: pointer;
  background-color: #d8d8d8;
}

.user_info .avatar:hover {
}

::v-deep .el-dialog {
  border-radius: 10px;
  animation: backInLeft; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.3s; /* don't forget to set a duration! */
}

::v-deep .el-dialog__body {
  padding: 0px 20px 20px 10px;
}

.el-form-item ::v-deep .el-upload-dragger {
  width: 40px;
  height: 40px;
}

</style>
