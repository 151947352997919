import axios from 'axios'
import errorCode from '@/utils/errorCode'
import {Notification, MessageBox, Message, Loading} from 'element-ui'
import {getToken} from '@/utils/auth'


export let isRelogin = {show: false};
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: 'https://www.zhangyujixx.top', // 生产环境
  // baseURL: 'http://10.12.100.30:8080', // 测试环境
  // 超时
  timeout: 100000
})

// 请求拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
  }
  return config;
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code|| 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
      return res
    }
    if (code === 401) {
      if (!isRelogin.show) {
        isRelogin.show = true;
        MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          isRelogin.show = false;
          location.href = '/';
        }).catch(() => {
          isRelogin.show = false;
        });
      }
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === 500) {
      console.log("interceptors.response.500")
      Message({message: msg, type: 'error'})
      return Promise.reject(new Error(msg))
    } else if (code === 601) {
      Message({message: msg, type: 'warning'})
      return Promise.reject('error')
    } else if (code === 902) {
      Message({message: msg, type: 'warning'})
      return Promise.reject('error')
    }else {
      return res
    }
  },
  error => {
    console.log('err::::' , error)
    let {message} = error;
    Message({message: message, type: 'error', duration: 5 * 1000})
    return Promise.reject(error)
  }
)


export default service
